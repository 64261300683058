import image_background from "@/assets/home/image_background.webp";
import object_background from "@/assets/home/object_background.png";
import blur from "@/assets/home/blur.webp";
import filter from "@/assets/home/filter.webp";
import compress from "@/assets/home/compress.webp";
import resize from "@/assets/home/resize.webp";
import enhancer from "@/assets/home/enhancer.webp";
import animation from "@/assets/home/video_effect.mp4";
import eraser from "@/assets/home/eraser.mp4";
import storytelling from "@/assets/home/storytelling.webp";
import paraphraser from "@/assets/home/paraphraser.webp";
import suggarComments from "@/assets/home/suggar-comments.webp";
import videoEnhancer from "@/assets/home/videoEnhancer.mp4";
import faceSwapper from "@/assets/home/face_swapper.png";
import video_eraser from "@/assets/home/video_eraser.mp4";
import video_bg_remove from "@/assets/home/video_bg_remove.mp4";

const toolsConfig = [
  {
    collection: "Image Tools",
    children: [
      {
        //AI Remove and Change Background（抠图）
        title: "Portrait Background Remover",
        path: "/background-remover",
        name: "backgroundRemove",
        trackName: "bg_remover",
        describe: "人像抠图",
        thumbnail: image_background,
        isPro: false,
        component: () => import("@/views/models/background-remove/index.vue"),
        description: 'Remove unwanted background for your portrait image with remarkable precision.',
      },
      {
        title: "Object Background Remover",
        path: "/object-background-remover",
        name: "objectBackgroundRemove",
        trackName: "object_bg_remover",
        describe: "物体抠图",
        thumbnail: object_background,
        isPro: false,
        component: () => import("@/views/models/object-background-remover/index.vue"),
        description: 'Remove unwanted background for your product image automatically in one click.',
      },
      {
        //消除笔
        title: "Object Eraser",
        path: "/object-eraser",
        name: "objectEraser",
        trackName: "eraser",
        thumbnail: eraser,
        isPro: false,
        component: () => import("@/components/models/object-eraser/index.vue"),
        description: 'Erase unwanted objects, people, defects or watermarks to create flawless images with a simple swipe.',
      },
      {
        //高清化
        title: "Image Enhancer",
        path: "/image-enhancer",
        name: "imageEnhancer",
        trackName: "enhancer",
        thumbnail: enhancer,
        isPro: false,
        component: () => import("@/components/models/image-enhancer/index.vue"),
        description: 'Upscale your images by 2x in one click to meet your resolution requirements.',
      },
      {
        //Photo Filters and Effects（滤镜)
        title: "Photo Filters and Effects",
        path: "/image-filter",
        name: "imageFilter",
        trackName: "filter",
        thumbnail: filter,
        isPro: false,
        component: () => import("@/views/models/image-filter/index.vue"),
        description: 'Apply instant filters and effects on your photos to make them stand out.',
      },
      {
        //裁剪
        title: "Image Resizer",
        path: "/image-resizer",
        name: "imageResizer",
        trackName: "resizer",
        thumbnail: resize,
        isPro: false,
        component: () => import("@/views/models/image-resizer/index.vue"),
        description: 'Change the dimensions of any picture for social media, print and web.',
      },
      {
        //图片压缩
        title: "Image Compressor",
        path: "/image-compressor",
        name: "imageCompressor",
        thumbnail: compress,
        trackName: "compressor",
        isPro: false,
        component: () => import("@/views/models/image-compressor/imageCompressor.vue"),
        description: 'Easily reduce image size while optimizing for maximum quality.',
      },
      {
        //动态滤镜
        title: "Photo Animation Effects",
        path: "/effects",
        trackName: "animation",
        name: "effects",
        thumbnail: animation,
        isPro: false,
        component: () => import("@/components/models/imageDynamicEffects.vue"),
        description: 'Apply animation effects and turn monotonous photos into animated photos in just one click.',
      },
      // {
      //   //动态滤镜
      //   title: "face-replacement",
      //   path: "/face-replacement",
      //   trackName: "faceReplacement",
      //   name: "faceReplacement",
      //   thumbnail: animation,
      //   component: () => import("@/views/models/face-replacement/index.vue"),
      // },
      // {
      //   // 换脸
      //   title: "Face Swapper (Coming soon)",
      //   path: "/face-swapper",
      //   name: "Face Swapper",
      //   thumbnail: faceSwapper,
      //   trackName: "face_swapper",
      //   isPro: false,
      //   describe: "换脸",
      //   description: '',
      // },
      {
        title: "Background Blur",
        path: "/background-blur",
        name: "backgroundBlur",
        describe: "背景模糊",
        trackName: "blur",
        thumbnail: blur,
        isPro: false,
        component: () => import("@/views/models/ambiguous-background/index.vue"),
        description: 'Blur flashy or messy background to direct audience’s attention to the essential content of the image.',
      },
    ],
  },

  {
    collection: "Copywriting Tools",
    children: [
      {
        // 文本重写
        title: "AI Paraphraser",
        path: "/AI-paraphraser",
        name: "AIparaphraser",
        describe: "文本重写",
        trackName: "paraphraser",
        thumbnail: paraphraser,
        isPro: false,
        component: () => import("@/views/models/tex-revisor/index.vue"),
        description: 'Leverage AI technology to rewrite any text with ease. Get rid of duplicated content.',
      },
      {
        // 夸夸
        title: "Sugar Comments",
        path: "/sugar-comments",
        name: "sugarComments",
        describe: "夸夸",
        trackName: "sugar_comment",
        thumbnail: suggarComments,
        isPro: false,
        component: () => import("@/views/models/kua-kua/index.vue"),
        description: 'Let our tool sweeten your words and spread love with every comment.',
      },
      {
        // 看图说垃圾话
        title: "Photo Storytelling",
        path: "/photo-storytelling",
        name: "photoStorytelling",
        trackName: "storytelling",
        describe: "看图说垃圾话",
        thumbnail: storytelling,
        isPro: false,
        component: () => import("@/views/models/photo-story-teller/index.vue"),
        description: 'Conquer writer\'s block and create engaging stories for your photos - unlock the true potential of your photos.',
      },
    ],
  },
  {
    collection: "Video Tools",
    children: [
      {
        // 视频高清化
        title: "Video Enhancer",
        path: "/video-enhancer",
        name: "videoEnhancer",
        trackName: "video_enhancer",
        describe: "视频高清化",
        thumbnail: videoEnhancer,
        isPro: true,
        description: 'Upscale your video by 2x and improve video color balance, sharpness, and clarity.',
      },
      {
        // 视频抠图
        title: "Video Background Remover",
        path: "/video-background-remove",
        name: "video-background-remove",
        trackName: "video_bg_remover",
        thumbnail: video_bg_remove,
        isPro: true,
        describe: "视频抠图",
        description: 'Effortlessly isolate people from the background of any video, without the need of green screen.',
      },
      // {
      //   // 视频消除笔
      //   title: "Video Inpainting (Coming soon)",
      //   path: "/video-inpainting",
      //   name: "Video Inpainting",
      //   trackName: "video_inpainting",
      //   thumbnail: video_eraser,
      //   isPro: false,
      //   describe: "视频消除笔",
      //   description: '',
      // },
    ],
  },
  // {
  //   collection: "Animation Tools",
  //   children: [
  //     // {
  //     //   // 3d脸动
  //     //   title: "facial-movement",
  //     //   path: "/facial-movement",
  //     //   name: "facialMovement",
  //     //   describe: "3d脸动",
  //     //   component: () => import("@/views/models/3d-effect/index.vue"),
  //     // },
  //     // {
  //     //   // 视频抠图
  //     //   title: "video-background-remove",
  //     //   path: "/video-background-remove",
  //     //   name: "video-background-remove",
  //     //   describe: "视频抠图",
  //     //   component: () =>
  //     //     import("@/views/models/video-background-remove/index.vue"),
  //     // },
  //   ],
  // },
  //AI Artsy Background Generator(智能推荐背景)
  //Blur Background（背景模糊）
  //Object Eraser（消除笔）
  //AI One-Click Upscaler（高清化）
];

//通过当前路由获取当前工具的title
function getToolTitleByPath (path) {
  if (!path) {
    return;
  }
  let title;
  for (let collection of toolsConfig) {
    collection.children.forEach((tool) => {
      if (tool.path === path) {
        title = tool.title;
      }
    });
  }

  return title;
}
getToolTitleByPath("/imageFilter");

export { toolsConfig, getToolTitleByPath };
